import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CopyrightIcon from '@mui/icons-material/Copyright';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import BGJewel from '../assets/bg_jewel.jpeg'
export default function ContactUs(){
    return(
        <div className="contact-us bg-gradient-to-r from-gray-900 to-blue-900 px-10 lg:px-60 md:px-40 sm:px-20 pt-10 text-white" id="contactus" style={{ backgroundImage: `url(${BGJewel})`,backgroundSize:"cover"}}>
            {/* <div className="flex justify-between items-center">
                <div className="text-2xl font-semibold tracking-widest">
                    BillingZone
                </div>
                <div className="text-2xl font-semibold tracking-widest">
                </div>
            </div> */}
            <div className="mt-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                    <div className="page-links">
                        <div className="text-2xl font-semibold tracking-widest">
                            BillingZone
                        </div>
                        <ul className="list-disc">
                            <li className="font-medium p-2"><a href="#home">Home</a></li>
                            <li className="font-medium p-2"><a href="#chooseus">Why Us?</a></li>
                            <li className="font-medium p-2"><a href="#features">Features</a></li>
                            {/* <li className="font-medium p-2"><a href="#brands">Our Brands</a></li> */}
                            <li className="font-medium p-2"><a href="#watchDemo">Watch Demo</a></li>
                        </ul>
                    </div>
                    <div className="contact-details">
                        <h2 className="text-1xl font-semibold">Contact Us:</h2>
                        <ul className="list-disc">
                            <li className="p-2 font-medium flex gap-5 items-center">
                                <span><MailOutlineIcon/></span>
                                <a href="mailto:support.billingzone@magizh.net">
                                    support.billingzone@magizh.net
                                </a>
                            </li>
                            <li className="p-2 font-medium flex gap-5 items-center">
                                <span><WhatsAppIcon/></span>
                                <div>
                                    <a href="tel:+919042351779" className="block">
                                        +91 90423 51779
                                    </a>
                                    <a href="tel:+917339093992" className="block">
                                        +91 73390 93992
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='mt-4 pb-5 pt-5 border-t'>
                <div className='grid grid-cols-2'>
                    <div className='font-normal text-sm'>
                        <div className='flex items-center gap-2'>
                            <CopyrightIcon className='w-2 h-2'/> 
                            <span>2024 BillingZone All Rights Reserved</span>
                        </div> 
                    </div>
                    <div className='social-media text-right flex gap-2 items-center justify-end'>
                        <div><span><InstagramIcon/></span></div>
                        <div><span><FacebookIcon/></span></div>
                        {/* <div><span><YouTubeIcon className='w-10 h-10'/></span></div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}