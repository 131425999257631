import { Grid } from '@mui/material';
import pattern2 from '../assets/pattern-2.png';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Container, Box, Typography } from '@mui/material';
import axios from 'axios'
import { useState } from 'react';
export default function ChooseUs() {
    const [enqStatus,setEnq]=useState(false);
    const [enqMsg,setMesg]=useState("");
    const { handleSubmit, control,reset, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        console.log(data);
        try {
            // const response = await axios.post('https://billingzone.magizh.net/enquiry', data);
            const response = await axios.post('https://billingzone.magizh.net/enquiry', data,{
                headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                // You can add more headers here if needed
                }
            });
            
            console.log(response.data);
            if(response.data.status){
                setEnq(true);
                setMesg(response.data.message);
            }else{
                setEnq(true);
                setMesg("Something went wrong! Please try again");
            }
            reset(); // Reset the form after successful submission
          } catch (error) {
            console.error('Error submitting form:', error);
          }
       // reset();

    };
    return (
        <div className="bg-white block px-10 lg:px-60 md:px-40 sm:px-20 pt-10 pb-20" id="watchDemo">
            {/* <img src={pattern2} alt='pattern2' className='w-full h-full'></img> */}
            <div className="text-center flex flex-col justify-center items-center">
                <h1 className="text-4xl font-semibold">Experience the Future with Our Demo</h1>
                <p className='mt-2 italic'>Ready to see how our innovative solutions can transform your business? <br/> Take the first step towards enhanced efficiency and productivity by trying our exclusive demo.</p>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-20 mt-20 items-center">
                    <Container maxWidth="xl" style={{fontFamily:"Poppins"}}>
                        <h3 className='mb-2 font-semibold text-xl'>Submit Enquiry for Demo</h3>
                        {enqStatus && <div className='bg-green-300 p-2 text-green-800 font-semibold mb-2 shadow-md rounded-md'>{enqMsg}</div>}
                        <Box
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                boxShadow: 0,
                               
                            }}
                        >
                            {/*  <Typography variant="h5" component="div" gutterBottom>
                                Contact Form
                            </Typography> */}
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <Controller
                                        name="name"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Name is required' }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Name"
                                                variant="outlined"
                                                fullWidth
                                                error={!!errors.name}
                                                helperText={errors.name ? errors.name.message : ''}
                                            />
                                        )}
                                    />

                                </Grid>
                                <Grid item xs={6}>

                                    <Controller
                                        name="shopeName"
                                        control={control}
                                        defaultValue=""
                                        //rules={{ required: 'Shope Name is required' }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Shop Name"
                                                variant="outlined"
                                                fullWidth
                                            // error={!!errors.name}
                                            // helperText={errors.name ? errors.name.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>


                                    <Controller
                                        name="phone"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: 'Phone number is required',
                                            pattern: {
                                              value: /^[0-9]+$/,
                                              message: 'Enter a valid phone number',
                                            },
                                          }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Phone Number"
                                                variant="outlined"
                                                type="number"
                                                fullWidth
                                                error={!!errors.phone}
                                                helperText={errors.phone ? errors.phone.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>

                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        /*  rules={{
                                             required: 'Email is required',
                                             pattern: {
                                                 value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                                 message: 'Enter a valid email address',
                                             },
                                         }} */
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Email"
                                                variant="outlined"
                                                type="email"
                                                fullWidth
                                            // error={!!errors.email}
                                            // helperText={errors.email ? errors.email.message : ''}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Controller
                                name="message"
                                control={control}
                                defaultValue=""
                                // rules={{ required: 'Message is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Enquiry Message "
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        fullWidth
                                    //error={!!errors.message}
                                    // helperText={errors.message ? errors.message.message : ''}
                                    />
                                )}
                            />
                            <Button variant="contained" className="bg-blue-100 text-blue-900 p-4" type="submit">
                                Submit
                            </Button>
                        </Box>
                    </Container>

                    <div className="w-full bg-gray-300 flex justify-center items-center text-2xl text-gray-600 font-semibold h-full rounded-md">
                        <div className="">
                            <h3>Demo video will be uploaded soon</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}